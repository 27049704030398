.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #09d3ac;
}

#myform {
    text-align: center;
    padding: 5px;
    border: 1px dotted #ccc;
    margin: 2%;
}
.qty {
    width: 40px !important;
    height: 25px;
    text-align: center;
}
.qty2 {
    width: 60px !important;
    height: 25px;
    text-align: center;
}

.error { color:red;}


input.qtyplus { width:25px; height:25px;}
input.qtyminus { width:25px; height:25px;}

input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
  -webkit-appearance: none; 
  margin: 0; 
}

.datepicker-controls .select-month input {
    width: 90px;
}